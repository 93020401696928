import React from 'react'
import './Agriculture.css'
function Agriculture() {
  return (
    <div className='agriculture-section'>
      
      <div className="agriculture-heading">
      <h2>
        ELECTRIC AGRICULTURAL MACHINERY: WHERE ARE WE WITH REGARDS TO THE ELECTRIFICATION OF THE AGRICULTURAL WORLD?
      </h2>
      </div>
      <br />
      <button className='call-us' style={{padding: '5px', backgroundColor: '#7e7ee9', fontSize: '15px', color: 'white'}}>To Get A Quote: <a href="mailto:info@avionicinternational.com">Click Here</a></button>


      <div className="agri-paragraph">
        <p>
        Growing awareness of environmental issues, increasingly strict anti-pollution laws, and the constant progress of modern technology are all bringing us towards the fast evolution of electric propulsion in the agricultural sector. This technological transition is being felt across Europe, and leads to the undeniable necessity of switching to electric mobility.
        </p>
      </div>

      <div className="agriculture-heading">
      <h2>
      ELECTRIC AGRICULTURAL MACHINERY: ELECTRIFICATION AS A CORE NEED OF THE INDUSTRIAL REVOLUTION 4.0
      </h2>

      <p>
      The agricultural market is currently undergoing major technological changes, leading to demand for new equipment and features that can adapt to the changing times. Additionally, concerns over environmental problems appear to be a key concept spurring European agricultural vehicles towards a conversion to electric power. Long-term public policy aimed at improving air quality and reducing harmful emissions also lead to the creation of very strict regulations on the emission of pollutants.
      </p>
      <p>
      These are the main considerations urging the manufacturers of agricultural machinery, like tractors and operators, to electrify their vehicles: being more environmentally friendly, the lack of maintenence needs and reduced management costs are essential features of today’s agricultural vehicles, as are sustainability and efficiency – nowadays two cornerstones of the Industrial Revolution 4.0.


      </p>
      </div>
      
    </div>
  )
}

export default Agriculture