import React from 'react'
import './Cotton.css'

const quilting = require('./quilting.jpg')
const brushed = require('./brushed.jpg')
const egyptian = require('./egyptian.jpg')
const cottontwill = require('./cottontwill.jpg')
const organic = require('./organic.jpg')
const knitted = require('./knitted.jpg')
const wool = require('./wool.jpg');
const poplin = require('./poplin.jpg');
const linen = require('./linen.jpg');
const silk = require('./silk.jpg');
const poly = require('./poly.jpg')

const cotton_info = [
    {heading: 'Quilting Cotton', image: quilting,  description: 'Quilting cottons are also referred to as craft cottons, patchwork cotton, or printed cotton and are plain weave cottons that are light to medium weight cotton. They are a pretty closely woven fabric and there are an array of quilted cotton fabrics available that are utilized for making tops, tunics, aprons, blankets, quilts, dresses, and skirts.'},
    {heading: 'Brushed Cotton', image: brushed,  description: 'Brushed cotton is sometimes referred to as flannel or flannelette. it is a medium-weight cotton fabric that has surface fibers that are brushed on one side of the material which gives it hey very soft and warm look and feel. you can purchase it in a plain material, or checked printed or stripe material and it is most often used for casual shirts, children’s clothing, and linings for bedding comic coats, and jackets. When it comes to a lightweight flannelette, it is used many times to make nightgowns and pajamas.'},
    // {heading: 'Pima Cotton', description: 'Considered a higher-end type of cotton fabric, pima cotton boasts longer fibers than regular cotton. Smooth fabric that is very soft to the touch is created with pima cotton. It tends to be resistant to wrinkles and very durable. Several products are created from this type of fabric, including bed sheets and underwear.'},
    {heading: 'Egyptian Cotton', image: egyptian,  description: 'Known as the highest quality cotton fabric, Egyptian cotton is used to make a number of products, including higher thread count bed sheets that many people prefer over other types of sheets to sleep upon. You can expect to pay a little more at the cash register for anything made from this type of fabric.'},
    {heading: 'CottonTwill', image: cottontwill,  description: 'There are many fabrics that are created from a cotton twill weave fabric, including some linen fabrics, denim, chino, and gabardine. It is used in making pants, jackets, skirts, and much more. It is distinguished by its weave pattern that places diagonal lines in the fabric and it is very sturdy and durable. Cotton twill is also used in creating some types of upholstery fabric since it is so durable.'},
    // {heading: 'Cotton Lawn', description: 'Cotton lawn is a lightweight, plain-weave cotton fabric that has a crisp, smooth feel. It can be quite sheer but is reasonably strong due to the fine weave. Often used for blouses, children’s clothes, tablecloths, and blankets.'},
    {heading: 'Organic Cotton', image: organic,  description: 'Organic cotton fabric is created without any synthetic fiber being added into it and is usually free of chemicals, including fertilizers and pesticides, when the cotton is being grown by cotton producers.'},
    {heading: 'Knitted Fabric', image: knitted,  description: 'Knitted fabric is made with one single yarn that is looped continuously to create a braided look and multiple yarns are used to make a woven fabric, crossing over each other at right angles, to create the grain of the fabric. Stretching is a process that can be used to determine if a fabric is woven or a knit fabric. Some of the products made from knitted fabric created with multiple yarns are men’s and women’s button-up shirts, jeans and trousers, and even denim jackets.'},
    // {heading: 'Levant Cotton', description: 'Levant cotton comes from the Levant seeds of the Gossypium herbaceum cotton plant, which are also used for feed, oil extraction, and food. It is considered Old World cotton and is used by many commercial cotton producers in creating clothing and other products.'},
    {heading: 'Wool Fabric', image: wool,  description: 'With cotton wool fabric you end up with the best of both types of fibers: cotton fiber and wool fiber. This means you get the comfort of cotton with the resilience and strength from the wool fiber. This type of cotton blend can be worn all year round no matter what season it is outside. Cotton and wool blends are normally used in higher-end clothing, including tweed, chenille, brocade, and velvet.'},
    {heading: 'Poplin', image: poplin,  description: 'Poplin, also known as cotton broadcloth, is a medium-weight, plain-weave cotton fabric that is tightly woven and easy to work with when sewing. It is extremely versatile and is used often to produce blouses, dresses, and shirts. It can be a pure cotton blend or one that includes polyester fabric.'},
    {heading: 'Linen Fabric', image: linen,  description: 'Linen cotton mix fabric is very soft and of light to medium-weight. It often contains a blend of 50 percent linen and 50 percent cotton. It retains the look and feel of linen but is more robust and will not crease as much. Some clothing made from linen and cotton is a little itchy to your skin. Some products created with linen cotton fabric include skirts, sheets, jackets, and dresses.'},
    {heading: 'Silk Fabric', image: silk,  description: 'A mix of silk and cotton weave produces a lightweight fabric that is very soft to the touch and offers the drape and luster that silk provides. It is used most often to make blouses and dresses.'},
    // {heading: 'Sea Island Cotton', description: 'Sea Island cotton is the rarest type of cotton fabric type in the world as well as one of the finest versions of cotton fabric. It boasts a very fine, uniform texture that offers a silky lustre and feels great next to your skin. A variety of products are made from Sea Island cotton, including high-end men’s boxer shorts.'},
    // {heading: 'Voile', description: 'Voile is a soft and very sheer fabric type that is typically made of 100 percent cotton or of cotton blended with linen or polyester. It is a very lightweight cotton fabric that gets its name from the French term for veil. This fabric is used often in hot, tropical climates and for mosquito netting and sheer curtains for windows.'},
    {heading: 'Poly Cotton', image: poly,  description: 'Polycotton is usually a blend of 50 percent polyester and 50 percent cotton can sometimes be made of 65 percent cotton and polyester. It is a very lightweight plain-weave fabric that is very strong, durable, resistant to creasing, however, it is less breathable than other cotton fabrics so it can be hot and make you sweat if you wear it during the warmer months. It is often used to create children’s clothing, skirts, and aprons.'}
]


function Cotton() {
  return (
    <div className='cotton-clothes-section'>
        <h2 style={{textDecoration: 'underline'}}>Best Cotton Clothes at the most affordable rate</h2>
        <div className="clothes-info">
            {
                cotton_info.map((cloth)=>{
                    return(
                        <>
                            <div className="cloth-heading"><h3>{cloth.heading}</h3></div>
                            <div className="cloth-img">
                                <img style={{ width: '90vw', maxWidth: '700px' }} src={cloth.image} alt="" />
                            </div>
                            <div className="cloth-desc" style={{textAlign: 'left'}}>
                                <p>{cloth.description}</p>
                            </div>
                            <button className='call-us' style={{padding: '5px', backgroundColor: '#7e7ee9', fontSize: '15px', color: 'white'}}><a href="mailto:info@avionicinternational.com">Mail To Order</a></button>
                            <hr />
                        </>
                    )
                })
            }
        </div>
    </div>
  )
}

export default Cotton