import React from 'react'
import './Header.css'
import Company from './Company'
import Navigation from './Navigation'

function Header() {
  return (
    <div className='heading-section'>
        <Navigation/>
        <Company/>
    </div>
  )
}

export default Header