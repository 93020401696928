import React from 'react'
import './Footer.css'
function Footer() {
    return (
        <div className='footer-section'>
            <div className="sections">
                <div className="section sec-1">
                    <p>
                        Copyrights © Avionic International Pvt Ltd
                    </p>
                </div>

                <div className="secondary-sections">
                    <div className="section sec-2">
                        <h3>Owner</h3>
                        
                        <p>
                            Avinash Bhadoria
                        </p>
                        
                    </div>
                    <div className="section sec-3">
                        <h3>Solutions</h3>
                        <p>
                            IT Solutions
                        </p>

                        <p><a href="/agri-electric-products">
                        Agricultural Products
                        </a>
                            
                        </p>
                        <p>
                        <a href="/dry-fruits">
                        Dry Fruits
                                </a>
                            
                        </p>
                        <p>
                        <a href="/cotton-clothes">
                        Cotton Clothes
                        </a>
                            
                            
                        </p>
                        <p>
                            Spices
                        </p>
                        
                    </div>
                    <div className="section sec-4">
                        <h3>Contact Details</h3>
                        <p>
                            +91 9320066666
                        </p>
                        <p>
                            info@avionicinternational.com
                        </p>
                        
                        <div className="icons">
                        <i class="fa-brands fa-facebook"></i>
                        <i class="fa-brands fa-instagram"></i>
                        <i class="fa-brands fa-google"></i>
                        <i class="fa-brands fa-snapchat"></i>
                        </div>
                        

                    </div>
                </div>

            </div>
        </div>
    )
}

export default Footer