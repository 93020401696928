import './App.css';
import Footer from './Components/Footer/Footer';

// Package import
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Login from './Components/Pages/Login/Login';
import Home from './Components/Home';
import Header from './Components/Header/Header';
import Agriculture from './Components/Agriculture/Agriculture';
import Dryfruits from './Components/Dry Fruits/Dryfruits';
import Cotton from './Components/CottonClothes/Cotton';

function App() {
  return (
    <div className="App">
      <Header/>
      <Router>
        <Routes>
          <Route exact path='/' element={<Home/>}/>
          <Route exact path='/login' element={<Login/>}/>
          <Route exact path='/agri-electric-products' element={<Agriculture/>}/>
          <Route exact path='/dry-fruits' element={<Dryfruits/>}/>
          <Route exact path='/cotton-clothes' element={<Cotton/>}/>
        </Routes>
      </Router>
      <Footer/>
    </div>
  );
}

export default App;
