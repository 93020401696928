import React from 'react'
import './Company.css'
function Company() {
    return (
        <div className='company-heading'>
            <div className="heading">
                <h1>Avionic International</h1>
            </div>
            <div className="sub-head">
                <h3>
                    During our years in the industry
                    we are proud to have gained a
                    reputation across the Country.
                </h3>
            </div>
        </div>
    )
}

export default Company