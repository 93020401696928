import React, { useState } from 'react'
import './Navigation.css'


function Navigation() {

    const [smallMenu, setSmallMenu] = useState(false);


    return (
        <div className='header-section'>
            <div className="navigation">
                <a href="/">
                    <div className="logo">

                    </div>
                </a>

                {
                    !smallMenu ?
                        <>
                            <div className="section-links">
                                {/* <div className="links"><a style={{ color: 'white' }} href="#mission">Mission</a></div> */}
                                <div className="links"><a style={{ color: 'white' }} href="/#values">Values</a> </div>
                                <div className="links"><a style={{ color: 'white' }} href="/#commitment">Our Commitment</a> </div>
                                <div className="links"><a style={{ color: 'white' }} href="/#business">Our Business</a> </div>
                                <div className="links"><a style={{ color: 'white' }} href="/login">Register/Login</a></div>
                            </div>
                        </> :
                        <>
                            <div className="section-links-smallscreen">
                                {/* <div className="links"><a style={{ color: 'white' }} href="#mission">Mission</a></div> */}
                                <div className="links"><a style={{ color: 'black' }} href="/#values">Values</a> </div>
                                <div className="links"><a style={{ color: 'black' }} href="/#commitment">Our Commitment</a> </div>
                                <div className="links"><a style={{ color: 'black' }} href="/#business">Our Business</a> </div>
                                <div className="links"><a style={{ color: 'black' }} href="/login">Register/Login</a></div>
                            </div>
                        </>
                }



                <div className="bars" onClick={(e) => {
                    setSmallMenu(!smallMenu)
                }}>
                    <i class="fa-solid fa-bars"></i>
                </div>

            </div>
        </div>
    )
}

export default Navigation