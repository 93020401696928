import React, { useState } from 'react'
import './Login.css'

import axios from 'axios';
function Login() {

  const [user, setUser] = useState({
    email: '',
    phone: 0
  })

  const handleChange = (e) => {
    const { name, value } = e.target
    setUser({
      ...user,
      [name]: value
    })
  }

  const user_signup = (e) => {
    e.preventDefault();
    axios.post('https://avionicinternational.com/insert.php', user)
      .then(() => alert("Thank you for signing up to our website"))
      .then(()=>window.location.reload())
      .catch((err) => alert("You are already Signed Up"))
  }

  return (
    <>
      <h3 style={{ textAlign: 'center' }}>Login Form</h3>
      <div className='login-section'>
        <div className="login-form">
          <form method='post' onSubmit={user_signup}>
            <input type="email" value={user.email} onChange={handleChange}  name='email' placeholder='Enter Your Email' />
            <br />
            <input type="text" name='phone' value={user.phone} onChange={handleChange} placeholder='Enter Phone' /> <br />
            <button className='login-submit' type="submit">Submit</button>
          </form>
        </div>
      </div>
    </>
  )
}

export default Login