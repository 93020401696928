import React from 'react'
import './About.css'
// const about_info = [
//     { heading: 'Our Vision', desc: 'To bring together a perfect combination of skilled workforce, updated technologies and streamlined processes to ensure quality, reliability and reality in working To achieve our objectives within time limit.' },
//     { heading: 'Mission', desc: 'To be the most efficient International Export & Import, Company in India by meeting & exceeding customer expectations. Our mission to provide a superior quality ,cost effective end to end import and export service through strong customer focus , continuous improvement reliability and trust.' },
//     { heading: 'Values', desc: 'To enhance the quality of service.To Serve with an environment of professionalism, team work and excellence.To respect all environmental rules, regulations and legal requirements.To enhance customer value.' }
// ]

function About() {
    return (
        <div className='about-section'>
            <div className="about-heading">
                <h2>Our Vision</h2>
            </div>
            <div className="about-subheading">
                <div className="subhead-text">
                    <h5>
                    To bring together a perfect combination of skilled workforce, updated technologies and streamlined processes to ensure quality, reliability and reality in working To achieve our objectives within time limit.
                    </h5>
                </div>
            </div>

            
        </div>
    )
}

export default About