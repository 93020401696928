import React from 'react'
import './Business.css'
function Business() {
    return (
        <div className='business-section' id='business'>
            <div className="business-heading">
                <h2>We Deal With The Import And Export Of</h2>
            </div>

            <div className="expertise-slider">
                <div className="business biz-1">
                    <a href="/agri-electric-products">
                    <h3 style={{color: 'white'}}>
                        Agricultural Electric Products
                    </h3>
                    </a>
                    
                </div>
                <div className="business biz-2">
                    <a href="/dry-fruits">
                    <h3 style={{color: 'white'}}>
                        Dry Fruits
                    </h3>
                    </a>
                    
                </div>
                <div className="business biz-3">
                    <a href="/cotton-clothes">
                    <h3 style={{color: 'white'}}>
                        Cotton Clothes
                    </h3>
                    </a>
                </div>
                <div className="business biz-4">
                    <a href="/spices">
                    <h3 style={{color: 'white'}}>
                        Spices
                    </h3>
                    </a>
                    
                </div>
                <div className="business biz-5">
                    <h3>
                        IT Solutions
                    </h3>
                </div>
            </div>

        </div>
    )
}

export default Business