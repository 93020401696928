import React from 'react'
import About from './About/About'
import Business from './Business/Business'

import Values from './Values/Values'

function Home() {
  return (
    <div className='home-page'>
        <About/>
        <Values/>
        <Business/>

    </div>
  )
}

export default Home