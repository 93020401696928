import React from 'react'

import './Dryfruits.css'

function Dryfruits() {
    return (
        <div className='dry-fruits-section'>
            <div className="dry-fruits-section-heading">
                <h2>We Provide you With The Best Quality Dry Fruits</h2><hr />
            </div>

            <div className="dry-fruits-lists">
                <div className="dry-fruit" style={{ textAlign: 'left' }}>
                    <div className="dry-fruits-name">
                        <h3>Almonds (Badam)</h3>
                    </div>
                    <div className="dry-fruits-desc">
                        <p>
                            Almonds are one of the most commonly consumed dry fruits. They provide immense health benefits to people of all age groups. They can be consumed raw, soaked, or roasted.
                        </p>

                        <img src={require('./almond-dry-fruit.jpg')} alt="" style={{ width: '90vw', maxWidth: '700px' }} />
                        <p style={{ textDecoration: 'underline', textAlign: 'left' }}>
                            Benefits
                        </p>
                        <ol>
                            <li style={{ textAlign: 'left' }}>Treat heart diseases</li>
                            <li style={{ textAlign: 'left' }}>manage blood sugar level</li>
                            <li style={{ textAlign: 'left' }}>Help with weight management</li>
                        </ol>
                        <p style={{ textDecoration: 'underline', textAlign: 'left' }}>
                            1 ounce(28g) serving of Almonds:
                        </p>
                        <ul>
                            <li style={{ textAlign: 'left' }}>Protein 6 grams</li>
                            <li style={{ textAlign: 'left' }}>Fiber 4 grams</li>
                            <li style={{ textAlign: 'left' }}>Plus Vitamin E (35% of Daily Value)</li>
                            <li style={{ textAlign: 'left' }}>Magnesium (20% of Daily Value)</li>
                            <li style={{ textAlign: 'left' }}>Calcium (8% of Daily Value)</li>
                        </ul>

                        <button className='call-us' style={{padding: '5px', backgroundColor: '#7e7ee9', fontSize: '15px', color: 'white'}}><a href="mailto:info@avionicinternational.com">Mail To Order</a></button>
                    </div>
                </div>
                <hr />
                <div className="dry-fruit" style={{ textAlign: 'left' }}>
                    <div className="dry-fruits-name">
                        <h3>Pistachio(Pista)</h3>
                    </div>
                    <div className="dry-fruits-desc">
                        <p>
                        This sweet and unique dry fruit is mostly preferred as a snack. Pista is a well-known name in the variety of dry fruits. It is a rich source of 30 different minerals, vitamins, and phytonutrients.
                        </p>

                        <img src={require('./pistachios-dry-fruit.jpg')} alt="" style={{ width: '90vw', maxWidth: '700px' }} />
                        <p style={{ textDecoration: 'underline', textAlign: 'left' }}>
                            Benefits
                        </p>
                        <ol>
                            <li style={{ textAlign: 'left' }}>Good for the heart</li>
                            <li style={{ textAlign: 'left' }}>Manages diabetes</li>
                            <li style={{ textAlign: 'left' }}>Improves the production of hemoglobin and blood circulation</li>
                        </ol>
                        <p style={{ textDecoration: 'underline', textAlign: 'left' }}>
                            1 ounce(28g) serving of Pistachios:
                        </p>
                        <ul>
                            <li style={{ textAlign: 'left' }}>Protein 5.72 grams</li>
                            <li style={{ textAlign: 'left' }}>Carbs 7.7 grams</li>
                            <li style={{ textAlign: 'left' }}>Fiber 3grams</li>
                            <li style={{ textAlign: 'left' }}>159 calories</li>
                            <li style={{ textAlign: 'left' }}>Fat 12.85 grams</li>
                        </ul>
                        <button className='call-us' style={{padding: '5px', backgroundColor: '#7e7ee9', fontSize: '15px', color: 'white'}}><a href="mailto:info@avionicinternational.com">Mail To Order</a></button>

                    </div>
                </div>
                <hr />
                <div className="dry-fruit" style={{ textAlign: 'left' }}>
                    <div className="dry-fruits-name">
                        <h3>Cashew (Kaju)</h3>
                    </div>
                    <div className="dry-fruits-desc">
                        <p>
                        Cashews are commonly referred to as a nut, but they are actually seeds. These seeds can be very important in improving your overall health. </p>
                        <img src={require('./Cashew-Dry-Fruit.jpg')} alt="" style={{ width: '90vw', maxWidth: '700px' }} />
                        <p style={{ textDecoration: 'underline', textAlign: 'left' }}>
                            Benefits
                        </p>
                        <ol>
                            <li style={{ textAlign: 'left' }}>Helps with weight loss</li>
                            <li style={{ textAlign: 'left' }}>Controls blood sugar levels</li>
                            <li style={{ textAlign: 'left' }}>Improve your heart health</li>
                        </ol>
                        <p style={{ textDecoration: 'underline', textAlign: 'left' }}>
                            1 ounce(28g) serving of Cashew:
                        </p>
                        <ul>
                            <li style={{ textAlign: 'left' }}>157 calories</li>
                            <li style={{ textAlign: 'left' }}>Protein 5 grams</li>
                            <li style={{ textAlign: 'left' }}>Fat 12 grams</li>
                            <li style={{ textAlign: 'left' }}>Copper (67% of Daily Value)</li>
                            <li style={{ textAlign: 'left' }}>Fiber 1 grams</li>
                            <li style={{ textAlign: 'left' }}>Carbs 9 grams</li>
                            <li style={{ textAlign: 'left' }}>Iron (11% of Daily Value)</li>
                        </ul>
                        <button className='call-us' style={{padding: '5px', backgroundColor: '#7e7ee9', fontSize: '15px', color: 'white'}}><a href="mailto:info@avionicinternational.com">Mail To Order</a></button>


                    </div>
                </div>
                <hr />
                <div className="dry-fruit" style={{ textAlign: 'left' }}>
                    <div className="dry-fruits-name">
                        <h3>Apricot (Khumani)</h3>
                    </div>
                    <div className="dry-fruits-desc">
                        <p>
                        Apricots are small, orange color, tart-tasting fruits that are rich in various vitamins and minerals.</p>

                        <img src={require('./Apricot-Dry-Fruit.jpg')} alt="" style={{ width: '90vw', maxWidth: '700px' }} />
                        <p style={{ textDecoration: 'underline', textAlign: 'left' }}>
                            Benefits
                        </p>
                        <ol>
                            <li style={{ textAlign: 'left' }}>Protects your heart and eyes.</li>
                            <li style={{ textAlign: 'left' }}>Hydrates skin and reduces wrinkles</li>
                        </ol>
                        <p style={{ textDecoration: 'underline', textAlign: 'left' }}>
                            1 ounce(28g) serving of Apricots:
                        </p>
                        <ul>
                            <li style={{ textAlign: 'left' }}>Energy 16.8 calories</li>
                            <li style={{ textAlign: 'left' }}>Protein 0.49 grams</li>
                            <li style={{ textAlign: 'left' }}>Fat 0.14 grams</li>
                            <li style={{ textAlign: 'left' }}>Fiber 0.7 grams</li>
                            <li style={{ textAlign: 'left' }}>Carbs 3.89 grams</li>
                            <li style={{ textAlign: 'left' }}>Beta-Carotene 383mcg</li>
                            <li style={{ textAlign: 'left' }}>Vitamin A 33.6 mcg</li>
                        </ul>
                        <button className='call-us' style={{padding: '5px', backgroundColor: '#7e7ee9', fontSize: '15px', color: 'white'}}><a href="mailto:info@avionicinternational.com">Mail To Order</a></button>

                    </div>
                </div>
                <hr />
                <div className="dry-fruit" style={{ textAlign: 'left' }}>
                    <div className="dry-fruits-name">
                        <h3>Dates (Khajoor)</h3>
                    </div>
                    <div className="dry-fruits-desc">
                        <p>
                        This super delicious and iron-rich dry fruit is perfect for the winter season. Dates are super nutritious and can be savored in many different forms! You can add them to your dessert or eat them as it is. You can find seeded or deseeded dates super easily in the market. </p>

                        <img src={require('./Dates-dry-fruit.jpg')} alt="" style={{ width: '90vw', maxWidth: '700px' }} />
                        <p style={{ textDecoration: 'underline', textAlign: 'left' }}>
                            Benefits
                        </p>
                        <ol>
                            <li style={{ textAlign: 'left' }}> Proven to promote brain health.</li>
                            <li style={{ textAlign: 'left' }}>Helps manage sugar levels in the body.</li>
                        </ol>
                        <p style={{ textDecoration: 'underline', textAlign: 'left' }}>
                        1 Date (7 grams) serving:
                        </p>
                        <ul>
                            <li style={{ textAlign: 'left' }}>Energy 20 calories</li>
                            <li style={{ textAlign: 'left' }}>Protein 0.2 grams</li>
                            <li style={{ textAlign: 'left' }}>Sodium 0.14 grams</li>
                            <li style={{ textAlign: 'left' }}>Fiber 0.6 grams</li>
                            <li style={{ textAlign: 'left' }}>Carbs 5.3 grams</li>
                        </ul>
                        <button className='call-us' style={{padding: '5px', backgroundColor: '#7e7ee9', fontSize: '15px', color: 'white'}}><a href="mailto:info@avionicinternational.com">Mail To Order</a></button>

                    </div>
                </div>
                <hr />
                <div className="dry-fruit" style={{ textAlign: 'left' }}>
                    <div className="dry-fruits-name">
                        <h3>Walnuts (Akhrot)</h3>
                    </div>
                    <div className="dry-fruits-desc">
                        <p>
                        This super delicious and iron-rich dry fruit is perfect for the winter season. Dates are super nutritious and can be savored in many different forms! You can add them to your dessert or eat them as it is. You can find seeded or deseeded dates super easily in the market. 
                        </p>

                        <img src={require('./walnuts.jpg')} alt="" style={{ width: '90vw', maxWidth: '700px' }} />
                        <p style={{ textDecoration: 'underline', textAlign: 'left' }}>
                            Benefits
                        </p>
                        <ol>
                            <li style={{ textAlign: 'left' }}> Reduces the risk of cancer and heart diseases.</li>
                            <li style={{ textAlign: 'left' }}>Helps manage risk of diabetes.</li>
                        </ol>
                        <p style={{ textDecoration: 'underline', textAlign: 'left' }}>
                        1 Ounce(28g) serving of Walnut:
                        </p>
                        <ul>
                            <li style={{ textAlign: 'left' }}>Energy 185 calories</li>
                            <li style={{ textAlign: 'left' }}>Protein 4.3 grams</li>
                            <li style={{ textAlign: 'left' }}>Fiber 1.9 grams</li>
                            <li style={{ textAlign: 'left' }}>Fat 18.5 grams</li>
                            <li style={{ textAlign: 'left' }}>Carbs 3.9 grams</li>
                        </ul>
                        <button className='call-us' style={{padding: '5px', backgroundColor: '#7e7ee9', fontSize: '15px', color: 'white'}}><a href="mailto:info@avionicinternational.com">Mail To Order</a></button>

                    </div>
                </div>
                <hr />
                <div className="dry-fruit" style={{ textAlign: 'left' }}>
                    <div className="dry-fruits-name">
                        <h3>Raisins (Kishmis)</h3>
                    </div>
                    <div className="dry-fruits-desc">
                        <p>
                        Next on our dry fruits list is Raisins, also known as Kishmish in India. This naturally sweet dry fruit is a good source of sugars, fat, protein, dietary fiber, various vitamins, and minerals.
                        </p>

                        <img src={require('./raisins.jpg')} alt="" style={{ width: '90vw', maxWidth: '700px' }} />
                        <p style={{ textDecoration: 'underline', textAlign: 'left' }}>
                            Benefits
                        </p>
                        <ol>
                            <li style={{ textAlign: 'left' }}>Helps regulate blood pressure</li>
                            <li style={{ textAlign: 'left' }}>Aids in better digeston</li>
                        </ol>
                        <p style={{ textDecoration: 'underline', textAlign: 'left' }}>
                        1 Ounce(28g) serving of Raisins:
                        </p>
                        <ul>
                            <li style={{ textAlign: 'left' }}>Energy 42 calories</li>
                            <li style={{ textAlign: 'left' }}>Protein 0.5 grams</li>
                            <li style={{ textAlign: 'left' }}>Fiber 0.6 grams</li>
                            <li style={{ textAlign: 'left' }}>Fat 18.5 grams</li>
                            <li style={{ textAlign: 'left' }}>Carbs 11 grams</li>
                            <li style={{ textAlign: 'left' }}>Sodium 3.6</li>
                            <li style={{ textAlign: 'left' }}>Sugar 9.1 grams</li>
                        </ul>
                        <button className='call-us' style={{padding: '5px', backgroundColor: '#7e7ee9', fontSize: '15px', color: 'white'}}><a href="mailto:info@avionicinternational.com">Mail To Order</a></button>

                    </div>
                </div>
                <hr />
            </div>
        </div>
    )
}

export default Dryfruits