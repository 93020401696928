import React from 'react'
import './Values.css'

function Values() {
    return (
        <div className='values-section' id='values'>
            <div className="values-heading">
                <h2>Values We Possess</h2>
            </div>
            <div className="values-subhead">
                <div className="subhead-text-values">
                    <div className="value value-1">
                        <p>
                            To Enhance Quality of Services
                        </p>
                    </div>
                    <div className="value value-2">
                        <p>
                            To Serve with an environment of professionalism, team work and excellence.

                        </p>
                    </div>
                    <div className="value value-3">
                        <p>
                            To respect all environmental rules, regulations and legal requirements.

                        </p>
                    </div>
                    <div className="value value-4">
                        <p>
                            To enhance customer value.

                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Values